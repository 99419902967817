<template>
  <div class="doc-detail">
    <el-row :gutter="10" class="mb8">
      <el-col :span="12">
        <el-button
          plain
          icon="el-icon-download"
          size="mini"
          @click="downloadDoc"
          >下载档案</el-button
        >
      </el-col>
      <el-col :span="6" :offset="6">
        <el-form
          :model="queryParams"
          ref="queryForm"
          :inline="true"
          class="term-name-select"
        >
          <el-form-item prop="termName">
            <el-select
              v-model="queryParams.termName"
              placeholder="请选择学期"
              size="small"
            >
              <el-option
                v-for="(item, index) in constData.termList"
                :key="index"
                :label="item.termName"
                :value="item.termName"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <div class="doc-item">
      <h3 class="doc-item-title">基本信息</h3>
      <div class="basic-info-wrap">
        <div class="basic-info">
          <table class="table">
            <tr>
              <td class="row-or-col-title">姓名</td>
              <td>{{ headInfo.userName }}</td>
              <td class="row-or-col-title">身份证号</td>
              <td>{{ headInfo.card }}</td>
              <td class="row-or-col-title">学籍号</td>
              <td>{{ headInfo.loginName }}</td>
              <td class="align-center avatar" rowspan="4">暂无图片</td>
            </tr>
            <tr>
              <td class="row-or-col-title">性别</td>
              <td>{{ headInfo.sexCN }}</td>
              <td class="row-or-col-title">出生日期</td>
              <td>{{ headInfo._birthDate }}</td>
              <td class="row-or-col-title">民族</td>
              <td>{{ headInfo.ethnic }}</td>
            </tr>
            <tr>
              <td class="row-or-col-title">所在学校</td>
							<td>{{ headInfo._parentName }}</td>
              <td class="row-or-col-title">所在班级</td>
              <td>{{ headInfo._deptName }}</td>
              <td class="row-or-col-title">班级职务</td>
              <td>{{ headInfo.studentPosition }}</td>
            </tr>
            <tr>
              <td class="row-or-col-title">家庭住址</td>
              <td colspan="3">{{ headInfo.studentAddr }}</td>
              <td class="row-or-col-title">联系电话</td>
              <td>{{ headInfo.phoneNumber }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="doc-item">
      <h3 class="doc-item-title">学籍异动记录</h3>
      <el-table
        :data="unusual.list"
        border
        :header-cell-style="publicTitleColor"
        style="width: 100%"
        v-loading="unusual.loading"
      >
        <el-table-column
          prop="createTime"
          label="异动时间"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="typeName"
          label="异动类型"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="fromSchoolName"
          label="转出学校"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="fromClassName"
          label="转出班级"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="toSchoolName"
          label="转入学校"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="toClassName"
          label="转入班级"
          width="180"
        ></el-table-column>
        <el-table-column prop="cause" label="异动原因"></el-table-column>
      </el-table>
    </div>

    <div class="doc-item no-margin">
      <ul class="term-detail-list">
        <li>
          <div class="term-detail">
            <dl>
              <dt><h3>学期综合素质评价结果</h3></dt>
              <dd>
                <table class="table">
                  <thead>
                    <tr>
                      <td class="row-or-col-title">综素指标</td>
                      <td>思想品德</td>
                      <td>学业水平</td>
                      <td>身心健康</td>
                      <td>艺术素养</td>
                      <td>社会实践<br />(初中三年累计)</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="row-or-col-title">等第/分值</td>
                      <td>{{ fiveDimension.type301 }}</td>
                      <td>{{ fiveDimension.type309 }}</td>
                      <td>{{ fiveDimension.type320 }}</td>
                      <td>{{ fiveDimension.type333 }}</td>
                      <td>{{ fiveDimension.type341 }}</td>
                    </tr>
                  </tbody>
                </table>
              </dd>
            </dl>
            <dl>
              <dt><h3>体质检测成绩</h3></dt>
              <dd>
                <table class="table">
                  <thead>
                    <tr>
                      <th class="td-width-20-per">单项指标</th>
                      <th>检测结果</th>
                      <th>综素得分</th>
                      <th>等第</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>体重(千克)</td>
                      <td>{{ health.studentWeight }}</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <td>身高(厘米)</td>
                      <td>{{ health.studentHeight }}</td>
                      <td>--</td>
                      <td>--</td>
                    </tr>
                    <tr>
                      <td>体重指数(BMI)(kg/㎡)</td>
                      <td>{{ health.type1 }}</td>
                      <td>{{ health.type1GradesScore }}</td>
                      <td>{{ health.type1Level }}</td>
                    </tr>
                    <tr>
                      <td>肺活量(毫升)</td>
                      <td>{{ health.type2 }}</td>
                      <td>{{ health.type2GradesScore }}</td>
                      <td>{{ health.type2Level }}</td>
                    </tr>
                    <tr>
                      <td>50米跑(秒)</td>
                      <td>{{ health.type3 }}</td>
                      <td>{{ health.type3GradesScore }}</td>
                      <td>{{ health.type3Level }}</td>
                    </tr>
                    <tr>
                      <td>坐立体前驱(厘米)</td>
                      <td>{{ health.type4 }}</td>
                      <td>{{ health.type4GradesScore }}</td>
                      <td>{{ health.type4Level }}</td>
                    </tr>
                    <tr>
                      <td>立定跳远(厘米)</td>
                      <td>{{ health.type5 }}</td>
                      <td>{{ health.type5GradesScore }}</td>
                      <td>{{ health.type5Level }}</td>
                    </tr>
                    <tr>
                      <td>引体向上(男)/一分钟仰卧起坐(女)</td>
                      <td>{{ health.type6 }}</td>
                      <td>{{ health.type6GradesScore }}</td>
                      <td>{{ health.type6Level }}</td>
                    </tr>
                    <tr>
                      <td>1000米(男)/800米(女)(分秒)</td>
                      <td>{{ health.type7 }}</td>
                      <td>{{ health.type7GradesScore }}</td>
                      <td>{{ health.type7Level }}</td>
                    </tr>
                    <tr>
                      <td class="row-or-col-title">本学年体育测试成绩</td>
                      <td colspan="3">{{ health.totalGradesScore }}</td>
                    </tr>
                    <tr>
                      <td class="row-or-col-title">本学年体育测试综素得分</td>
                      <td colspan="3">{{ health.totalIndexScore }}</td>
                    </tr>
                  </tbody>
                </table>
              </dd>
            </dl>

			<template v-for="(item,index) in academic">
              <dl :key="index" v-if="item.title && item.title.length>0">
                <dt><h3>学业水平{{item.examType}}成绩</h3></dt>
                <dd>
                  <el-table
                    :data="item.content"
                    style="width: 100%"
                    border
                    :header-cell-style="publicTitleColor">
                    <el-table-column
                      v-for="(tit, tindex) in item.title"
                      :key="tindex"
                      :prop="tit.code"
                      :label="tit.name">
                    </el-table-column>
                  </el-table>
                </dd>
              </dl>
            </template>
			
            <dl>
              <dt>
                <h3>参赛记录</h3>
              </dt>
              <dd>
                <el-table
                  border
                  :data="match.list"
                  :header-cell-style="publicTitleColor"
                  style="width: 100%"
                  v-loading="match.loading"
                >
                  <el-table-column
                    prop="matchTime"
                    label="参赛时间"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    prop="matchName"
                    label="赛事名称"
                  ></el-table-column>
                  <el-table-column
                    prop="matchType"
                    label="比赛类别"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    prop="indexRuleLevel"
                    label="赛事级别"
                    width="100"
                  ></el-table-column>
                </el-table>
              </dd>
            </dl>
            <dl>
              <dt>
                <h3>荣誉记录</h3>
              </dt>
              <dd>
                <el-table
                  border
                  :data="honor.list"
                  :header-cell-style="publicTitleColor"
                  style="width: 100%"
                  v-loading="honor.loading"
                >
                  <el-table-column
                    prop="createTime"
                    label="获得时间"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    prop="honorName"
                    label="荣誉名称"
                  ></el-table-column>
                  <el-table-column
                    prop="indexRuleLevel"
                    label="级别"
                    width="180"
                  ></el-table-column>
                </el-table>
              </dd>
            </dl>
            <dl>
              <dt>
                <h3>违纪记录</h3>
              </dt>
              <dd>
                <el-table
                  border
                  :data="breach.list"
                  :header-cell-style="publicTitleColor"
                  style="width: 100%"
                  v-loading="breach.loading"
                >
                  <el-table-column
                    prop="createTime"
                    label="违纪时间"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    prop="breakContext"
                    label="违纪内容"
                  ></el-table-column>
                  <el-table-column
                    prop="breakScore"
                    label="扣分值"
                    width="180"
                  ></el-table-column>
                </el-table>
              </dd>
            </dl>
            <dl>
              <dt>
                <h3>社会实践记录</h3>
              </dt>
              <dt>
                <el-table
                  border
                  :data="practice.list"
                  :header-cell-style="publicTitleColor"
                  style="width: 100%"
                  v-loading="practice.loading"
                >
                  <el-table-column
                    prop="practiceTime"
                    label="时间"
                    width="200"
                  ></el-table-column>
                  <el-table-column
                    prop="practiceTitle"
                    label="实践主题"
                  ></el-table-column>
                  <el-table-column
                    prop="practiceAddress"
                    label="实践地点"
                    width="400"
                  ></el-table-column>
                  <el-table-column
                    prop="practiceLabel"
                    label="指标标签"
                    width="180"
                  ></el-table-column>
                </el-table>
              </dt>
            </dl>
			<dl>
			  <dt>
			    <h3>课堂表现评价</h3>
			  </dt>
			  <dd>
			    <table class="table">
			      <thead>
			        <tr>
			          <th class="row-or-col-title">评价项目</th>
			          <th class="row-or-col-title">学生自评</th>
			          <th class="row-or-col-title">学生互评</th>
			          <th class="row-or-col-title">教师评价</th>
			          <th class="row-or-col-title">评价结果</th>
			        </tr>
			      </thead>
			      <tbody>
			        <tr>
			          <td>{{ classPerform.termName }}</td>
			          <td>{{ classPerform.selfScore }}</td>
			          <td>{{ classPerform.otherScore }}</td>
			          <td>{{ classPerform.teacherScore }}</td>
			          <td>{{ classPerform._totalScore }}</td>
			        </tr>
			      </tbody>
			    </table>
			  </dd>
			</dl>
            <dl>
              <dt>
                <h3>学期评价</h3>
              </dt>
              <dd>
                <table class="table">
                  <thead>
                    <tr>
                      <td class="row-or-col-title td-width-20-per">学生自评</td>
                      <td>--</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="row-or-col-title">教师评价</td>
                      <td>--</td>
                    </tr>
                  </tbody>
                </table>
              </dd>
            </dl>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getTerm } from "@/api/public/search";
import { studentDocDetail } from "@/api/public/studentDoc";
import axios from "axios";
import { getToken } from "@/utils/auth";
export default {
  data() {
    return {
      queryParams: {
        userId: "",
        termName: "",
      },
      constData: {
        termList: [],
      },
      currentActive: [],
      headInfo: {},
      unusual: {
        list: [],
        loading: false,
      },
      breach: {
        list: [],
        loading: false,
      },
      examScore: {},
      fiveDimension: {},
      health: {},
      honor: {
        list: [],
        loading: false,
      },
      match: {
        list: [],
        loading: false,
      },
      practice: {
        list: [],
        loading: false,
      },
      classPerform: {},
      idNumReg: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
			academic: [],
			termDes: ''
    };
  },
  created() {
    this.queryParams.userId = sessionStorage.getItem("userIdFromStudentDocBtn");
    this.getTermList();
  },
  watch: {
    "queryParams.termName"(newV, oldV) {
      if (oldV != newV) {
        this.getTermDetail();
      }
    },
  },
  methods: {
    // 表头样式
    publicTitleColor() {
      return "color: #4A4A4A;font-weight: bold;";
    },
    // 获取学期列表
    getTermList() {
      getTerm({}).then((res) => {
        let data = res.data;
        this.constData.termList = data;
        this.queryParams.termName = data[0].termName;
      });
    },
    // 档案查询
    getTermDetail() {
      this.breach.list = [];
      this.examScore = {};
      this.fiveDimension = {};
      this.health = {};
      this.honor.list = [];
      this.honor.loading = false;
      this.match.list = [];
      this.match.loading = false;
      this.practice.list = [];
      this.practice.loading = false;
      this.classPerform = {};
	  this.academic = []
      studentDocDetail({
        term: this.queryParams.termName,
      }).then((res) => {
        let data = res.data;
        data.userInfo.sexCN = ["", "男", "女"][data.userInfo.sex];
        if (this.idNumReg.test(data.userInfo.card)) {
          let birthDate = data.userInfo.card.substr(6, 8);
          let year = birthDate.substr(0, 4);
          let mon = birthDate.substr(4, 2);
          let date = birthDate.substr(6, 2);
          let joinStr = "-";
          data.userInfo._birthDate = year + joinStr + mon + joinStr + date;
        } else {
          data.userInfo._birthDate = "--";
        }
        data.userInfo._deptName = data.userInfo.dept.deptName || "";
        data.userInfo._parentName = data.userInfo.dept.parentName || "";
        this.headInfo = data.userInfo;
        this.unusual.list = data.schoolUnusuals;
        let detail = data.termDetail[this.queryParams.termName];
        if (detail) {
          this.breach.list = detail.breakList;
        //   this.examScore = detail.examScoreDto;
		  this.academic = detail.exams
          this.fiveDimension = detail.fiveDimension.dimensionBo;
          this.health = detail.health.physiqueUser;
          this.honor.list = detail.honorList;
          this.match.list = detail.matchList;
          this.practice.list = detail.practiceRecordUserList;
          detail.showevalProcess._totalScore =
            detail.showevalProcess.selfScore +
            detail.showevalProcess.otherScore +
            detail.showevalProcess.teacherScore;
          this.classPerform = detail.showevalProcess;
					this.termDes = detail.termDes
        }
      });
    },
    // 下载档案
    downloadDoc() {
      axios({
        baseURL: process.env.VUE_APP_API,
        url: "/shida-user-biz/upload/downStudentPdf",
        method: "post",
        headers: {
          Authorization: getToken(),
          "Content-Type": "application/json",
        },
        data: {
          userId: this.queryParams.userId,
        },
        //         responseType: "blob",
      }).then((res) => {
        //         console.log(res);
        let blob = this.dataURItoBlob(res.data.data);
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = "个人档案.pdf"; //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      });
    },
    // base64文件流转为blob
    dataURItoBlob(dataURI) {
      // data:application/pdf;base64,
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]; // mime类型
      var byteString = atob(dataURI.split(",")[1]); //base64 解码
      var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
      var intArray = new Uint8Array(arrayBuffer); //创建视图

      for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      return new Blob([intArray], { type: mimeString });
    },
  },
};
</script>

<style scoped>
.doc-detail {
  padding: 20px;
  background-color: #fff;
}
.term-name-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.term-name-select .el-form-item {
  margin-right: 0;
}
.basic-info-wrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.basic-info {
  flex: 1 1 auto;
}
.avatar {
  width: 120px;
}
.doc-item {
  margin-bottom: 30px;
}
.doc-item.no-margin {
  margin-bottom: 0;
}
.doc-item:after,
.doc-item-title {
  display: block;
  clear: both;
}
.doc-item:after {
  content: "";
}
.term-detail > dl {
  margin-top: 0;
  margin-bottom: 50px;
}
.term-detail > dl dt {
  margin-bottom: 10px;
}
.term-detail > dl dd {
  margin: 0;
}
.table {
  width: 100%;
  border: 1px solid #ebeef5;
  border-collapse: collapse;
  color: #606266;
}
.td-width-20-per {
  width: 20%;
}
.table th,
.table td {
  padding: 12px 10px;
  border: 1px solid #ebeef5;
}
.align-center {
  text-align: center;
}
.table th,
.row-or-col-title {
  color: #4a4a4a;
  font-weight: bold;
}
.term-detail-title {
  position: relative;
  border: 1px solid #ebeef5;
  padding: 10px;
  margin-bottom: 0;
  margin-top: 0;
}
</style>
